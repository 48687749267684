import React, { useEffect} from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';
import img4 from '../../assets/img/listpic4.png';
import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service4 from '../../assets/Service4_lwrbnr.png';
import servicebnr from '../../assets/service4_bnr.png';
import Projects from '../../CommonComponents/Projects';
import Service4_dev from '../../assets/service4-dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const MagentoDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const List = [
        {
            name: 'eCommerce Development',
        },
        {
            name: 'Open-Source Customization',
        },
        {
            name: 'Mobile App Development',
        },
        {
            name: 'MVP Development',
        },
        {
            name: 'Migration/Upgradation',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. Seamless Integration:',
            content:`In today's interconnected world, seamless integration with third-party services and payment gateways is essential to the success of any e-commerce venture.`
        }, 
        {
            title: '2. Commitment to Excellence:',
            content:'We pride ourselves on our commitment to excellence, reflected in every project we undertake. From MVP development to migration and updates, we adhere to the highest standards of quality and professionalism.'
        },
        {
            title: '3. Continuous Support: ',
            content: 'Our relationship with our clients does not end with the launch of your Magento website. We offer ongoing support and maintenance services to ensure your online store remains optimized, secure, and up-to-date.'
        }
    ]
    const TopFeatures = {feature1:' Seamless Integration',feature2:'Commitment to Excellence', feature3:'Continuous Support'}
    const ListOfDevelopement = [
        {
            name: 'Open Source',
            img: img2,
            details: 'Magento, Woocommerce, OpenCart'
        },
        {
            name: 'Web Application',
            img: img4,
            details: 'Angular, React, Vue.js, Node, Laravel,'
        },
        {
            name: 'Hosted Platform',
            img: img4,
            details: 'Shopify, BigCommerce'
        },
        {
            name: 'Database',
            img: img2,
            details: 'MySQL, MongoDB, PostgreSQL, Firebase'
        }
    ];

    return (
        <div>
            <Banner Heading='Magento Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Your Ideal Custom Magento Development Company' SubHeading='At CreationSoul, we are more than just a Magento development company; We are your strategic partner to elevate your eCommerce presence to unprecedented heights. Here is why choosing us is the right decision for your custom Magento development needs' Pic={service4} />
            <Listing  Heading='Business-Friendly' SubHeading='Magento Development Services for Different Platforms' Display='none' List={List} />
            <Details  contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading="With our team of highly skilled Magento developers, we bring years of collective experience to the table. Our expertise spans the entire spectrum of Magento development, from customization to maintenance, ensuring your e-commerce project is in capable hands. We understand that every business is unique, and off-the-shelf solutions often cannot meet specific requirements. That's why we specialize in providing bespoke eCommerce websites and applications tailored precisely to your needs. Be it custom development, PSD to Magento conversion, or extension development, we ensure that your vision comes to life seamlessly. We use avant-garde technologies and latest IDEs to create robust, secure and future-ready e-commerce solutions. . By staying ahead of the curve, we ensure that your online store not only meets current standards but also stands the test of time." />
            <DevelopementService className="s_listing1" List={ListOfDevelopement} Pic={Service4_dev} Heading='Magento development at creationsoul' SubHeading='We made eCommerce website for many platforms' />
            <Industries />
            <Hiring />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default MagentoDevelopment;
