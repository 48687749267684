import React, { Suspense, useEffect} from 'react';
import TopRated from '../CommonComponents/Top_rated/TopRated';
import '../IT Staff Augmentation Services/Style.css';
import img2 from '../assets/img/listpic3.png';
import img4 from '../assets/img/listpic4.png';
import Listing from '../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../CommonComponents/Hiring/Hiring';
import blueArrow from '../assets/img/blueArrow.svg';
import Feedback from '../CommonComponents/Feedback';
import MoreCaseStudies from '../CommonComponents/MoreCaseStudies';
import service4 from '../assets/Service4_lwrbnr.png';
import servicebnr from '../assets/service4_bnr.png';
// import Projects from '../CommonComponents/Projects';
import Service4_dev from '../assets/service4-dev.png';
import Quote from '../CommonComponents/Quote';
import Banner from '../CommonComponents/ServiceBanner/Banner';
import Industries from '../CommonComponents/Industries_served/Industries';
import DevelopementService from '../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';
import Details from '../CommonComponents/ServiceDetails/details';
const Projects = React.lazy(() => import('../CommonComponents/Projects'));

const EcommerceDevelopement = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const List = [
        {
            name: 'eCommerce Development',
        },
        {
            name: 'Open-Source Customization', 
        },
        {
            name: 'Mobile App Development', 
        },
        {
            name: 'MVP Development', 
        },
        {
            name: 'Migration/Upgradation', 
        },
        {
            name: 'Issues & Bug Fixes', 
        }
    ];
    const DetailsContent = [
        {
            title: '1. Reliable Maintenance:',
            content:`The smallest problem with your application can have a significant impact on your business revenue. That's why our experienced e-commerce developers are available to resolve any issue immediately, ensuring a seamless user experience.`
        }, 
        {
            title: '2. Seamless upgrade:',
            content:'Staying ahead of the curve is crucial as technology evolves. Our top-rated eCommerce developers have the expertise to migrate your application from one platform to another seamlessly.'
        },
        {
            title: '3. MVP development for testing ideas:  ',
            content: 'Looking to test your business ideas without committing massive resources? Our experienced eCommerce developers can help you create a Minimum Viable Product (MVP).'
        }
    ]
    const TopFeatures = {feature1:'Reliable Maintenance',feature2:'Seamless upgrade', feature3:'MVP development for testing ideas'}
    const ListOfDevelopement = [
        {
            name: 'Open Source',
            img: img2,
            details: 'Magento, Woocommerce, OpenCart'
        },
        {
            name: 'Web Application',
            img: img4,
            details: 'Angular, React, Vue.js, Node, Laravel,'
        },
        {
            name: 'Hosted Platform',
            img: img4,
            details: 'Shopify, BigCommerce'
        },
        {
            name: 'Database',
            img: img2,
            details: 'MySQL, MongoDB, PostgreSQL, Firebase'
        }
    ];

    return (
        <div>
            <Banner Heading='E-Commerce Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Your premier destination for eCommerce development solutions' SubHeading='At CreationSoul, we pride ourselves on being a top-rated eCommerce development company dedicated to providing tailored web and mobile app solutions for eCommerce enterprises. With a focus on security, reliability, speed and responsiveness, we strive to enhance your online presence and drive exceptional user experiences.' Pic={service4} />
            <Listing  Heading='Business-Friendly' SubHeading='E-Commerce Development Services for Different Platforms' Display='none' List={List} />
            <Details contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading={`At CreationSoul, we understand that every business is unique and needs are not the same. That's why our team of experts carefully analyzes your requirements to deliver e-commerce development services. Whether creating customer-centric websites or mobile applications, we ensure that our solutions maximize your ROI and take your business to new heights. Using the power of open-source e-commerce platforms like Magento, Shopify and WooCommerce, we provide cost-effective solutions that save you both time and money. Our experienced professionals guide you in selecting the best platform that matches your specific requirements, ensuring seamless integration and optimal performance. In today's digital landscape, a responsive mobile application is essential to enhance the omnichannel customer experience.`} />
            <DevelopementService className="s_listing1" List={ListOfDevelopement} Pic={Service4_dev} Heading='E-Commerce development at creationsoul' SubHeading='We made eCommerce website for many platforms' />
            <Industries />
            <Hiring />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Suspense fallback={<Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#002EF4"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{ margin: 'auto', marginTop: '5rem' }}
                    wrapperClass=""
                />}>
                    <Projects />
                </Suspense>

                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default EcommerceDevelopement;
