import React, { Suspense, useEffect } from "react";
import TopRated from "../CommonComponents/Top_rated/TopRated";
import "../IT Staff Augmentation Services/Style.css";
import img1 from "../assets/img/listpic1.png";
import img2 from "../assets/img/listpic3.png";
import img3 from "../assets/img/listpic5.png";
import img4 from "../assets/img/listpic4.png";
import Listing from "../CommonComponents/MobileDevelopmentServicesListing/Listing";
import Hiring from "../CommonComponents/Hiring/Hiring";
import blueArrow from "../assets/img/blueArrow.svg";
import Feedback from "../CommonComponents/Feedback";
import MoreCaseStudies from "../CommonComponents/MoreCaseStudies";
import mobile from "../assets/mobile_dev.png";
import Service1 from "../assets/img/Service1.png";
import toprated from "../assets/TopratedImg2.png";
import Quote from "../CommonComponents/Quote";
import Banner from "../CommonComponents/ServiceBanner/Banner";
import Industries from "../CommonComponents/Industries_served/Industries";
import DevelopementService from "../CommonComponents/Developement_services/DevelopementService";
import { Link } from "react-router-dom";
import { Triangle } from "react-loader-spinner";

const Projects = React.lazy(() => import("../CommonComponents/Projects"));

const MobileAppDevelopement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const List = [
    {
      name: "App Designing",
      img: img1,
      details:
        "We can further enhance your app experience with cutting-edge design that engages users.",
    },
    {
      name: "Custom App Development",
      img: img2,
      details:
        "Our custom app development solutions provide tailored fulfillment thereby meeting the needs of business requirements.",
    },
    {
      name: "Web to App",
      img: img3,
      details:
        "Seamlessly bridge the gap between web and mobile with our web-to-app conversion service.",
    },
    {
      name: "Migration & Update",
      img: img1,
      details:
        "Stay ahead of the curve with our migration and update services, rest assured that your app is top-notch.",
    },
    {
      name: "Maintenance",
      img: img3,
      details:
        "We strive to keep your app in prime condition with our meticulous maintenance practices. Perform technical improvements, regular code reviews, testing, and design updates.",
      },
      {
        name: "Testing & Bug Fixing",
        img: img4,
        details:
          "We strive to keep you on the right track by achieving perfection through our extensive testing and bug-fixing solutions.",
      },
  ];
  const ListOfDevelopement = [
    {
      name: "IOS App Development",
      img: img2,
      details:
        "Front-end: Swift, Objective-C Back-end: Node, Laravel, Firebase, Python, Java, Dot Net, PHP, GraphQL",
    },
    {
      name: "Android -App Development",
      img: img2,
      details:
        "Front-end: Swift, Objective-C Back-end: Node, Laravel, Firebase, Python, Java, Dot Net, PHP, GraphQL",
    },
  ];

  return (
    <div>
      <Banner
        Heading="Custom Mobile App Development Services"
        SubHeading="Designed For Entrepreneurs"
        Pic={Service1}
      />
      <TopRated
        Heading="A leading company in customized mobile app solutions We have an exceptional mobile experience" SubHeading="At CreationSoul, we specialize in creating mobile app solutions precisely to our clients' specifications.
        Using the latest advancements in technology, CreationSoul is dedicated to developing custom mobile applications across various platforms. Be it native apps or cross-platform solutions, we prioritize understanding your unique requirements and provide a comprehensive service for both Android and iOS platforms. Our focus remains on ensuring maximum security and providing outstanding UI/UX design in all our applications. Increase your mobile presence by hiring our team of skilled mobile app development engineers."
        Pic={toprated}
      />
      <Listing
        Heading="Business-Friendly"
        SubHeading="The best platform for providing business-centric mobile app development services"
        Display="none"
        List={List}
      />
      <DevelopementService
        className="s_listing"
        List={ListOfDevelopement}
        Pic={mobile}
        Heading="Mobile app development at creationsoul"
        SubHeading="We made mobile app for both android and IOS"
      />
      <Industries />
      <Hiring page="mobile_App" />
      <Feedback />
      <MoreCaseStudies />
      <div className="cont_project">
        <Suspense
          fallback={
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#002EF4"
              ariaLabel="triangle-loading"
              wrapperStyle={{ margin: "auto", marginTop: "5rem" }}
              wrapperClass=""
            />
          }
        >
          <Projects />
        </Suspense>
        <Link to="/case-study">
          <button className="all">
            View More <img src={blueArrow} alt="arrow" />
          </button>
        </Link>
      </div>
      <Quote />
    </div>
  );
};

export default MobileAppDevelopement;
