import React from 'react';
import color from '../img/Rectangle 47.png';
import './Style.css';

const MoreCaseStudies = () => {
    return (
        <div className='main_sec'>
            <h2><img src={color} alt="color" />See our more case studies</h2>
        </div>
    );
};

export default MoreCaseStudies;
