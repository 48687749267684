import React, { Suspense, useEffect } from "react";
import TopRated from "../CommonComponents/Top_rated/TopRated";
import "../IT Staff Augmentation Services/Style.css";
import img2 from "../assets/img/listpic3.png";
import arrow from "../assets/arrow.png";
import arrow2 from "../assets/arrow2.png";
import arrow3 from "../assets/arrow3.png";
import Listing from "../CommonComponents/WebDevelopmentServicesListing/Listing";
import Hiring from "../CommonComponents/Hiring/Hiring";
import blueArrow from "../assets/img/blueArrow.svg";
import Feedback from "../CommonComponents/Feedback";
import MoreCaseStudies from "../CommonComponents/MoreCaseStudies";
import service3 from "../assets/Service3_lwrbnr.png";
import servicebnr from "../assets/service3_bannerTop.png";
import { Triangle } from "react-loader-spinner";
import servDev from "../assets/service3_dev.png";
import Quote from "../CommonComponents/Quote";
import Banner from "../CommonComponents/ServiceBanner/Banner";
import Industries from "../CommonComponents/Industries_served/Industries";
import DevelopementService from "../CommonComponents/Developement_services/DevelopementService";
import { Link } from "react-router-dom";
import Details from "../CommonComponents/ServiceDetails/details";
const Projects = React.lazy(() => import("../CommonComponents/Projects"));

const WebDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const List = [
    {
      name: "Custom application development",
      img:arrow
    },
    {
      name: "E-commerce",
      img:arrow2
    },
    {
      name: "Application Maintenance",
      img:arrow3
    },
    {
      name: "API Development",
      img:arrow
    },
    {
      name: "CMS Development",
      img:arrow2
    },
    {
      name: "Issues & Bug Fixes",
      img:arrow3
    },
  ];
  const DetailsContent = [
    {
        title: '1. Flexible Hiring Options: ',
        content:'Whether you need a full-time developer for ongoing projects or prefer a pay-as-you-go model for on-demand requirements, we offer flexible hiring options to suit your needs.'
    }, 
    {
        title: '2. Exceptional Support:',
        content: 'Our relationship with clients does not end once the project is delivered. We provide ongoing support and maintenance to ensure your web application continues to perform at its best.'
    },
    {
        title: '3. Proven Track Record:',
        content: 'With a portfolio of successful projects and satisfied clients, CreationSoul has established itself as a trusted name in the custom web application development industry.'
    }
]
const TopFeatures = {feature1:' Flexible Hiring Options',feature2:'Exceptional Support', feature3:'Proven Track Record'}
  const ListOfDevelopement = [
    {
      name: "Front-End Technologies",
      img: img2,
      details: "Angular, React, Vue.js, Bootstrap",
    },
    {
      name: "Backend-Technologies",
      img: img2,
      details: "PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB",
    },
  ];
  return (
    <div>
      <Banner
        Heading="Custom Web App Development Services"
        SubHeading="Designed For Entrepreneurs"
        Pic={servicebnr}
      />
      <TopRated
        Heading="Enhance your online presence with our premier custom web application development services"
        SubHeading="CreationSoul, where we specialize in creating custom web applications tailored to your unique needs. With a dedicated team of experienced professionals, we guarantee not only impressive designs but also measurable business results. Here's why you should choose CreationSoul for all your custom web application development needs"
        Pic={service3}
      />
      <Listing
        Heading="Business-Friendly"
        SubHeading="Web App Development Services for Different Platforms"
        Display="none"
        List={List}
      />
      <Details  contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading={`At CreationSoul, we understand that one size does not fit all. This is why we focus on creating bespoke web applications tailored specifically to your requirements. Whether you need a custom e-commerce platform, API development, CMS solutions, or application maintenance, we've got you covered. We know the devil is in the details. Our team pays meticulous attention to every aspect of your project, ensuring that even the smallest elements contribute to a seamless user experience. From layout readability to content presentation, we leave no stone unturned to deliver excellence. Staying ahead of the curve is essential in the rapidly evolving world of technology. At CreationSoul, we use the latest tools and technologies to build web applications that not only meet your current needs but also future-proof your business. Our commitment to innovation ensures that your online presence remains competitive and relevant. Choose CreationSoul for your custom web application development needs and experience the difference firsthand. Let us bring your vision to life and take your business to new heights in the digital world. Contact us today to get started!`} />
      <DevelopementService
        className="s_listing"
        List={ListOfDevelopement}
        Pic={servDev}
        Heading="Web app development at creationsoul"
        SubHeading="We made mobile app for both android and IOS"
      />
      <Industries />
      <Hiring />
      <Feedback />
      <MoreCaseStudies />
      <div className="cont_project">
        <Suspense
          fallback={
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#002EF4"
              ariaLabel="triangle-loading"
              wrapperStyle={{ margin: "auto", marginTop: "5rem" }}
              wrapperClass=""
            />
          }
        >
          <Projects />
        </Suspense>
        <Link to="/case-study">
          <button className="all">
            View More <img src={blueArrow} alt="arrow" />
          </button>
        </Link>
      </div>
      <Quote />
    </div>
  );
};

export default WebDevelopment;
