// Hiring.js
import React, { useEffect, useState } from "react";
import "./Style.css";
import color from "../../assets/img/Rectangle 47.png";
import Contact from "../Contact-Part/Contact";

const Hiring = ({ page }) => {
  const [selectedOption, setSelectedOption] = useState("fixedCost");
  const [contactContent, setContactContent] = useState(true);
  const fixedCost = () => {
    setContactContent(false);
    setSelectedOption("fixedCost");
  };
  const fullTime = () => {
    setContactContent(true);
    setSelectedOption("fullTime");
  };

  const partTime = () => {
    setContactContent(false);
    setSelectedOption("partTime");
  };

  useEffect(() => {
    if (page !== "mobile_App") {
      setSelectedOption("fullTime");
    }
  }, []);
  return (
    <div className="ovr">
      <div className={"hiring"}>
        <div className="upr_part">
          <h2>
            <img className="color" src={color} alt="" />
            Flexible Hiring Options For On-Demand IT Staff Augmentations
          </h2>
        </div>
        <div className="lwr_btns">
          {page === "mobile_App" ? (
            <>
              <button onClick={fixedCost} className="new_lft_btn">
                Fixed Cost Hiring
              </button>
              <button onClick={fullTime} className="new_rht_btn">
                Full Time Hiring
              </button>
              <button onClick={partTime} className="new_rht_btn">
                PAY AS YOU GO HIRING (HOURLY HIRING)
              </button>
            </>
          ) : (
            <>
              <button onClick={fullTime} className="lft_btn">
                Full Time Hiring
              </button>
              <button onClick={partTime} className="rht_btn">
                PAY AS YOU GO HIRING (HOURLY HIRING)
              </button>
            </>
          )}
        </div>
      </div>
      <div
        className={`contact-container ${
          contactContent ? "contact-slide-out" : "contact-slide-in"
        }`}
      >
        {contactContent && (
          <Contact selectedOption={selectedOption} page={page} />
        )}
      </div>
      <div
        className={`contact-container ${
          !contactContent ? "contact-slide-out1" : "contact-slide-in1"
        }`}
      >
        {!contactContent && (
          <Contact selectedOption={selectedOption} page={page} />
        )}
      </div>
    </div>
  );
};

export default Hiring;
