import React, { useEffect } from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';

import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service3 from '../../assets/Service3_lwrbnr.png';
import servicebnr from '../../assets/service3_bannerTop.png';
import Projects from '../../CommonComponents/Projects';
import servDev from '../../assets/service3_dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const ReactDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const List = [
        {
            name: 'Custom application development',
        },
        {
            name: 'E-commerce',
        },
        {
            name: 'Application Maintenance',
        },
        {
            name: 'API Development',
        },
        {
            name: 'CMS Development',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. Driving Digital Innovation:',
            content:'At CreationSoul, we believe feedback is the foundation of digital transformation. We follow in the footsteps of tech giants like Apple, Netflix, and PayPal, leveraging feedback to push the boundaries of innovation and elevate the digital experience to new heights.'
        }, 
        {
            title: '2. Endless Possibilities:',
            content: 'From dashboard applications to e-commerce platforms, and social networks to learning management systems, CreationSoul explores countless applications where responsiveness shines brightest. Our expertise spans across domains, enabling us to power your digital journey with unmatched performance and user experience.'
        },
        {
            title: '3. Collaborative Approach:',
            content: 'We see our clients as partners in the journey of digital excellence. Our collaborative approach ensures that your input is valued at every step, resulting in solutions that truly reflect your brand and resonate with your audience.'
        }
    ]
    const TopFeatures = {feature1:' Driving Digital Innovation',feature2:'Endless Possibilities', feature3:'Collaborative Approach'}
    const ListOfDevelopement = [
        {
            name: 'Front-End Technologies',
            img: img2,
            details: 'Angular, React, Vue.js, Bootstrap'
        },
        {
            name: 'Backend-Technologies',
            img: img2,
            details: 'PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB'
        }
    ];
    return (
        <div>
            <Banner Heading='React App Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Improve your performance and user experience' SubHeading='Using React, we power your digital efforts with unmatched performance and user engagement. Our expert React developers create seamless, dynamic interfaces that redefine the user experience across platforms. Whether you are kickstarting a novel web application or converting existing ones to Responsive, our experts ensure the best results per your requirements. Grow your digital presence with our premier React development services. Partner with our seasoned React engineers today.' Pic={service3} />
            <Listing  Heading='Business-Friendly' SubHeading='React App Development Services for Different Platforms' Display='none' List={List} />
            <Details contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading={`Our experienced React developers are adept at creating seamless, dynamic interfaces that enhance user experience across platforms. With a keen eye for detail and a passion for innovation, we ensure that every project exceeds expectations. Whether you're launching a groundbreaking web application or upgrading existing ones to become responsive, our experts build solutions to precisely meet your needs. We understand that every project is unique, and we're committed to delivering results that match your vision. With React's virtual DOM, we optimize performance to deliver lightning-fast user interfaces. By embracing feedback, we unlock a realm of benefits, including seamless SEO integration, rapid migration, and accelerated development cycles. At CreationSoul, we don't just build apps; We create digital experiences that leave a lasting impression. Partner with us today, and let's redefine the future, one line of code at a time.`} />
            <DevelopementService className="s_listing" List={ListOfDevelopement} Pic={servDev} Heading='Reat app development at creationsoul' SubHeading='We made mobile app for both android and IOS' />
            <Industries/>
            <Hiring page="react_App" />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default ReactDevelopment;
