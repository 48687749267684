import React from "react";
import "./Styles.css";
import CustomserviceIcon from "../../assets/custom-app-dev.png";
import Accordion from "../../Accordion/accordion";
import { FaCheck } from "react-icons/fa6";
const Details = ({ contentList, TopFeatures, SubHeading }) => {
  console.log("SubHeading is", SubHeading);
  const demoContentList = [
    {
      title: "1. Tailored Solutions:",
      content:
        "Custom Apps Are Designed To Address The Unique Challenges And Goals Of A Business. Developers Work Closely With Clients To Understand Their Workflows, Processes, And Requirements, Ensuring That The Final Product Meets Their Exact Specifications.",
    },
    {
      title: "2. Tailored Solutions:",
      content:
        "Custom Apps Are Designed To Address The Unique Challenges And Goals Of A Business. Developers Work Closely With Clients To Understand Their Workflows, Processes, And Requirements, Ensuring That The Final Product Meets Their Exact Specifications.",
    },
    {
      title: "3. Tailored Solutions:",
      content:
        "Custom Apps Are Designed To Address The Unique Challenges And Goals Of A Business. Developers Work Closely With Clients To Understand Their Workflows, Processes, And Requirements, Ensuring That The Final Product Meets Their Exact Specifications.",
    },
  ];
  return (
    <div className="detailsContainer">
      <div className="detailsInnerLeft">
        <div className="headingContainer">
          <div className="serviceImgContainer">
            <img src={CustomserviceIcon} className="serviceImg" />
          </div>
        </div>
        <h3>
          {SubHeading == undefined
            ? `custom app development is a dynamic process that involves creating software applications tailored to the specific needs and requirements of a business or organization. Unlike off-the-shelf solutions, which offer a one-size-fits-all approach, custom apps are designed from the ground up, offering unparalleled flexibility, scalability, and functionality. This bespoke approach to software development allows businesses to address their unique challenges and opportunities, empowering them to streamline processes, boost productivity, and drive innovation.
One of the key advantages of custom app development is the ability to create tailored solutions that align closely with the goals and objectives of the business. By working closely with clients to understand their workflows, processes, and pain points, developers can design custom apps that address these specific needs. Whether it's automating repetitive tasks, improving collaboration between teams, or enhancing customer engagement, custom apps can be built to deliver tangible business value.`
            : SubHeading}
        </h3>
      </div>
      <div className="detailsInnerRight">
        {contentList?.length > 0
          ? contentList?.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                content={item.content}
              />
            ))
          : demoContentList.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
        <h2>Top Features</h2>
        <div className="topFeaturesContainer">
          <div className="topFetauresLeft">
            {TopFeatures ? (
              <>
                <div className="innerTopFeatureFirst">
                  <div className="iconCircle">
                    <FaCheck color="#FFFFFF" />
                  </div>
                  <p>{TopFeatures.feature1}</p>
                </div>

                <div className="innerTopFeatureSecond">
                  <div className="iconCircle">
                    <FaCheck color="#FFFFFF" />
                  </div>
                  <p>{TopFeatures.feature2}</p>
                </div>
              </>
            ) : (
              <>
                <div className="innerTopFeatureFirst">
                  <div className="iconCircle">
                    <FaCheck color="#FFFFFF" />
                  </div>
                  <p>User Authentication</p>
                </div>
                <div className="innerTopFeatureSecond">
                  <div className="iconCircle">
                    <FaCheck color="#FFFFFF" />
                  </div>
                  <p>Intuitive User Interface (UI)</p>
                </div>{" "}
              </>
            )}
          </div>

          <div className="topFetauresRight">
            {TopFeatures ? (
              <>
                <div className="innerTopFeatureThird">
                  <div className="iconCircle">
                    <FaCheck color="#FFFFFF" />
                  </div>
                  <p>{TopFeatures.feature3}</p>
                </div>
                <div className="innerTopFeatureFourth">
                  {TopFeatures.feature4 &&
                    <div className="iconCircle">
                      <FaCheck color="#FFFFFF" />
                    </div>}
                  <p>{TopFeatures.feature4}</p>
                </div>{" "}
              </>
            ) : (
              <>
                <div className="innerTopFeatureFirst">
                  <div className="iconCircle">
                    <FaCheck color="#FFFFFF" />
                  </div>
                  <p>Push Notifications</p>
                </div>
                <div className="innerTopFeatureSecond">
                  <div className="iconCircle">
                    <FaCheck color="#FFFFFF" />
                  </div>
                  <p>Offline Functionality</p>
                </div>{" "}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
