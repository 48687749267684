import React from "react";
import "./Style.css";
import lftImg from "../../assets/img/Contact_lft_img.png";
import { Link } from "react-router-dom";

const Contact = ({selectedOption, page}) => {
  return (
    <div className="contact_part">
      <div className="lft_part">
        <img className="lftimg" src={lftImg} alt="" />
      </div>

      <div className="rht_part">
        {page === "mobile_App" ? (
          <>
            <p>
              {selectedOption === 'fixedCost'   ? `Start your project with certainty through our fixed-cost hiring model. With this approach, you'll get a comprehensive analysis of your project's requirements, which will result in a set cost that remains unchanged throughout development. This model thrives on clarity and preparation, making it ideal for those with a clear vision in mind and a detailed scope of work. Start your app development journey with confidence, starting at $7000.` : selectedOption === "fullTime" ?
                 "We have full-time hiring options. You will be provided with a dedicated developer who works for 160 hours per month i.e. 8 hours a day, 5 days a week. This developer becomes an integral part of your team, collaborating on your projects remotely With this plan, you can seamlessly adapt to evolving project requirements."
                : selectedOption === "partTime"  ? "Introducing our pay-as-you-go recruitment option - we come up with an innovative solution tailored to your needs. With this plan, you can block off hours to enlist the skills of our developers for small tasks over some time. There is no expiration date on your hourly restrictions. Be agile, contact us for our Pay-as-you-go recruitment." : null}
            </p>
            <small>
              {selectedOption === "fullTime" 
                ? 'Prices for full-time app developers range from $1800 to $3000, depending on their experience level. Choose the perfect fit for your needs and enjoy the benefits of having a skilled professional on board to tackle your development challenges.'
                : null}
            </small>
          </>
        ) : (
          <>
            <p>
              {selectedOption === "fullTime" 
                ? "This scheme employs a developer who works 8 hours a day, 5 days a week, for a total of 160 hours per month. After task assignment, the developer assumes the role of your remote employee. This plan is suitable for projects characterized by frequently required changes."
                : selectedOption === "partTime"  ? `This plan gives you the flexibility to hire a developer for small tasks within a specific time frame by purchasing a block of hours. Once you've used up your hours, you can refill them with another block with no expiration date in the hour bank. Whenever you have a new task, contact the account manager to resume the task. This system is ideal for on-demand requirements such as minor updates and bug fixes.` : null}
            </p>
            <small>
              {selectedOption === "fullTime" 
                ? "Prices for a full-time web developer range from $1800 to $3000, depending on their skill level."
                : selectedOption === "partTime"  ? "Blocks of hours can be earned in multiples of 20, with hourly rates starting at $15." : null}
            </small>
          </>
        )}
        <Link to="/contactUs">
          <button className="contact_btn bg-reverse">Contact Us</button>
        </Link>
      </div>
    </div>
  );
};

export default Contact;
