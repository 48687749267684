import React, { useState, useRef } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import "./accordion.css"; // Import CSS for styles

const Accordion = ({ title,key, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordian" key={key}>
      <div
        className={`${isOpen ? "accordion-open" : "accordion-header"}`}
        onClick={toggleAccordion}
      >
              <h2 className={`heading ${isOpen ? 'open' : 'closed'}`}>{title}</h2>
        {isOpen ? (
          <div className="MinusIcon">
            <FaMinus color="#FFFFFF" />
          </div>
        ) : (
          <div className="PlusIcon">
            {" "}
            <FaPlus color="#F29743" />
          </div>
        )}
      </div>
      <div
        ref={contentRef}
        className={`accordion-content ${isOpen ? "open" : "closed"}`}
        style={{ maxHeight: isOpen ? contentRef.current.scrollHeight : 0 }}
      >
        {content}
      </div>
    </div>
  );
};

export default Accordion;
