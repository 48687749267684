import React, { useState } from "react";
import "./Style.css";
import color from "../../assets/img/Rectangle 47.png";

const Listing = ({
  List,
  Heading,
  SubHeading,
  Display
}) => {
  const [initialIndex, setInitialIndex] = useState(0)
  const handleServiceChange = (index) => {
    setInitialIndex(index);


  }
  return (
    <div className="service_listing">
      <div className="inr_listing">
        <div className="upr_part">
          <h2>
            <img className="color" src={color} alt="" />
            {Heading}
          </h2>
          <h3>{SubHeading}</h3>
        </div>

        <div className="s_listing">
          {List.map((data, index) => {
            return (
              <div className="listContainer">
                 {/* <img src={data.img} className={`${index == 0 || index == 2 || index == 3 || index == 5 ? 'arrowImage' : 'arrowDownImage'}`} /> */}
                  
              
                <div
                  key={index}
                  className={index == initialIndex ? 'list-active' : 'list'}
                  onClick={() => handleServiceChange(index)}
                >
                 
                  <h3>{data.name}</h3>
                </div>
              </div>
            );
          })}
        </div>
        <button style={{ display: Display }} className="viewAll_btn">
          View All
        </button>
      </div>
    </div>
  );
};

export default Listing;
