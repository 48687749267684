import image1 from '../img/image 2.png';
import image2 from '../img/image 3.png';
import image3 from '../img/image 4.png';
import image4 from '../img/image 5.png';
import image5 from '../img/image 6.png';
import image6 from '../img/image 7.png';
const Data = [
    {
        pic: image1,
        projectName: "E-Commerce Website",
        details: "Journey to Excellence: Unveiling Creation Soul's Travel Website Case Studies"
    },
    {
        pic: image2,
        projectName: "E-Commerce Website",
        details: "Journey to Excellence: Unveiling Creation Soul's Travel Website Case Studies"
    },
    {
        pic: image3,
        projectName: "E-Commerce Website",
        details: "Journey to Excellence: Unveiling Creation Soul's Travel Website Case Studies"
    },
    {
        pic: image4,
        projectName: "E-Commerce Website",
        details: "Journey to Excellence: Unveiling Creation Soul's Travel Website Case Studies"
    },
    {
        pic: image5,
        projectName: "E-Commerce Website",
        details: "Journey to Excellence: Unveiling Creation Soul's Travel Website Case Studies"
    },
    {
        pic: image6,
        projectName: "E-Commerce Website",
        details: "Journey to Excellence: Unveiling Creation Soul's Travel Website Case Studies"
    }
];

export default Data;