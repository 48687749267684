import React, { useEffect } from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';
import img4 from '../../assets/img/listpic4.png';
import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service4 from '../../assets/Softwear_lwrbnr.png';
import servicebnr from '../../assets/Softwear_bnr.png';
import Projects from '../../CommonComponents/Projects';
import Service5_dev from '../../assets/Softwear__dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';


const SoftwareDevelopementInner = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const List = [
        {
            name: 'SaaS Application ',
            
        },
        {
            name: 'Custom CMS',
            
        },
        {
            name: 'Custom CRM',
            
        },
        {
            name: 'eCommerce Application',
            
        },
        {
            name: 'Mobile Apps',
            
        },
        {
            name: 'Migration & Update',
            
        }
    ];
    const DetailsContent = [
        {
            title: '1. Comprehensive support and maintenance:',
            content:'Our commitment to excellence does not end with final product delivery. We provide comprehensive support and maintenance services to ensure your software is up-to-date, secure and optimized for performance.'
        }, 
        {
            title: '2. Cost-Effective Solutions: ',
            content:`We understand the importance of maximizing ROI without compromising on quality. That's why CreationSoul offers affordable custom software development services that help you achieve your business goals without breaking the bank.`
        },
        {
            title: '3. Get started today:',
            content: 'Ready to turn your ideas into reality? Contact CreationSoul today for a free, no-obligation consultation.'
        }
    ]
    const TopFeatures = {feature1:'Comprehensive support and maintenance',feature2:'Cost-Effective Solutions', feature3:'Get started today'}
    const ListOfDevelopement = [
        {
            name: 'Mobile Apps',
            img: img2,
            details: 'React Native, Flutter, Kotlin, Swift'
        },
        {
            name: 'Database',
            img: img4,
            details: 'MySQL, MongoDB, PostgreSQL, Firebase'
        },
        {
            name: 'Web Application',
            img: img4,
            details: 'Angular, React, Vue.js, Node, Laravel,'
        }
    ];
    return (
        <div>
            <Banner Heading='Software Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Software Development Company' SubHeading={`CreationSoul is a company where innovation meets excellence in custom software development. As an award-winning company, we pride ourselves on providing bespoke software solutions tailored to your unique business needs. With our wide range of services and commitment to excellence, here's why CreationSoul stands out as the premier choice for your custom software development needs`} Pic={service4} />
            <Listing Heading='Business-Friendly' SubHeading='Software Development Services for Different Platforms' Display='none' List={List} />
            <Details  contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading={`Whether you need a SaaS application, custom CMS, CRM, e-commerce platform, or mobile application, CreationSoul has the expertise to bring your vision to life. Our seasoned software engineers are adept at using the latest technologies to develop scalable, secure, high-performing solutions across multiple platforms. We understand the importance of time and budget constraints in today's fast-paced business environment. We follow agile development processes, ensuring efficient project management and timely solution delivery. With CreationSoul, you can rest assured that your project will be completed on schedule and within budget. At CreationSoul, customer satisfaction is at the core of everything we do. We work closely with our clients to understand their unique needs and preferences, ensuring that the end product exceeds their expectations. Our interactive and customer-friendly software applications are designed to enhance user experience and drive business growth. Choose CreationSoul as your custom software development partner and immediately experience the difference.`}/>
            <DevelopementService className="s_listing1" List={ListOfDevelopement} Pic={Service5_dev} Heading='Software Development at creationsoul' SubHeading='Our custom software development services are designed to address your specific needs' />
            <Industries/>
            <Hiring />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'> <button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default SoftwareDevelopementInner;
